import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  qrcode: {
    background: "white",
    display: "flex",
    justifyContent: "center",
    justifySelf: "center",
  },
  textEnd: {
    textAlign: "end",
  },
  textCenter: {
    align: "center"
  },
  value: {
    color: theme.palette.primary?.main,
    fontWeight: "bold",
  },
  paddingLeft: {
    paddingLeft: "1rem",
  },
  paddingRight: {
    paddingRight: "1rem",
  },
  paperContainerLarge: {
    padding: "1.5rem 1rem",
  },
  paperContainer: {
    padding: "1rem",
  },
  spacing: {
    padding: "1rem 1rem",
  },
  errorContainer: {
    color: theme.palette.grey?.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    textAlign: "center",
  },
}));
