import React, { useEffect } from "react";
import {
  FormControlLabel,
  TextField,
  Grid,
  Switch,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  overstayRateId: Yup.string().required("Overstay Rate is required."),
  accessGroupId: Yup.string().required("Access Group is required."),
  defaultExpirationTime: Yup.date()
    .nullable()
    .typeError("Invalid time"),
});

const CreateOnePass = ({ options, rates, onePassDetails, onSave }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: { ...onePassDetails },
  });

  const onSubmit = async (data) => {
    await onSave({ ...data, active: true });
  };

  useEffect(() => {
    reset(onePassDetails);
    trigger(["overstayRateId", "accessGroupId"]);
  }, [onePassDetails, reset, trigger]);
 
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={4} spacing={2} direction="column">
          <Grid item>
            <Controller
              name="accessGroupId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={options}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Access Group"
                      fullWidth
                      variant="outlined"
                      required
                      error={!!errors.accessGroupId}
                      helperText={errors.accessGroupId?.message}
                    />
                  )}
                  value={
                    options.find((option) => option.value === field.value) ||
                    null
                  }
                  onChange={(event, value) =>
                    field.onChange(value ? value.value : "")
                  }
                  className="autocomplete-access-group"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="overstayRateId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={rates}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Overstay Rate"
                      fullWidth
                      variant="outlined"
                      required
                      error={!!errors.overstayRateId}
                      helperText={errors.overstayRateId?.message}
                    />
                  )}
                  value={
                    rates.find((rate) => rate.value === field.value) || null
                  }
                  onChange={(event, value) =>
                    field.onChange(value ? value.value : "")
                  }
                  className="autocomplete-overstay-rate"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="defaultExpirationTime"
              control={control}
              render={({ field }) => (
                <KeyboardTimePicker
                  {...field}
                  margin="normal"
                  id="time-picker"
                  label="Default Expiration Time"
                  value={field.value}
                  onChange={(time) => {
                    field.onChange(time);
                    trigger("defaultExpirationTime"); 
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  inputVariant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={4}
          direction="column"
          spacing={2}
          justifyContent="center"
        >
          <Grid item>
            <Controller
              name="showNameField"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      data-checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      name="showNameField"
                      color="primary"
                    />
                  }
                  label="Show Name Field"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="showRoomField"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      data-checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      name="showRoomField"
                      color="primary"
                    />
                  }
                  label="Show Room Field"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} justifyContent="flex-end">
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || isSubmitting || !isDirty}
              className="enable-one-pass-send-button"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateOnePass;
