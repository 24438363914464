import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import clsx from "clsx";
import { useStyles } from "./styles";
import { BrowserQRCodeSvgWriter } from '@zxing/browser'; 
import { EncodeHintType } from '@zxing/library'; 

const OnePassDetails = ({ ticketId, validFrom, validTo, status }) => {
  const classes = useStyles();
  const [barcodeScan, setBarcodeScan] = useState(ticketId);
  const codeWriter = new BrowserQRCodeSvgWriter();
  const hints = new Map();
  hints.set(EncodeHintType.MARGIN, 5);
  const svgElement = codeWriter.write(barcodeScan, 200, 200, hints);
  const svgHtml = svgElement.outerHTML;
  
  const formatDt = (dt) => {
    return moment(dt).format("l LT");
  };

  const formatStatus = (status) => {
    switch (status) {
      case 0: return "Unset";
      case 1: return "In";
      case 2: return "Out";
      case 3: return "Neutral";
    }
  };

  return (
    <>
      <Grid>
        <div className={clsx(classes.qrcode, "ticket-id")} dangerouslySetInnerHTML={{__html: svgHtml }}></div>
      </Grid>

      <Grid
        container
        data-id="OnePassInfo"
        className={clsx(classes.spacing)}
        data-valid-from-date={formatDt(validFrom)}
        data-valid-to-date={formatDt(validTo)}
      >
        <Grid item xs={1} className={clsx(classes.value)} />
        <Grid item xs={3} className={clsx(classes.value)}>
          Valid From:
        </Grid>
        <Grid item xs={6} className={clsx(classes.textEnd, classes.value)}>
          {formatDt(validFrom)}
        </Grid>
        <Grid item xs={2} className={clsx(classes.value)} />
        
        <Grid item xs={1} className={clsx(classes.value)} />
        <Grid item xs={3} className={clsx(classes.value)}>
          Valid To:
        </Grid>
        <Grid item xs={6} className={clsx(classes.textEnd, classes.value)}>
          {formatDt(validTo)}
        </Grid>
        <Grid item xs={2} className={clsx(classes.value)} />

        <Grid item xs={6} className={clsx(classes.value, classes.textEnd, classes.paddingRight)}>
          Status: 
        </Grid>
        <Grid item xs={6} className={clsx(classes.value)}>
          {formatStatus(status)}
        </Grid>
        
      </Grid>
    </>
  );
};

export default OnePassDetails;
